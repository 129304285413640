import { Button, Card, Col, Form, Input, Row, Select, Space } from 'antd';
import DisplayAlert from '../../../components/Commons/Alert';
// import { create } from '../../../services/bookingClients.service';
import { AlertService } from '../../../services/alert.service';
import { getAll as loadWorkers } from '../../../services/workers.service';
import { useMutation, useQuery } from 'react-query';
import { getCompanyDetails, sendLinkApp } from '../../../services/companies.service';
import { useEffect } from 'react';

const { TextArea } = Input;

export default function AppPage() {
    const [formApp] = Form.useForm();

    const { data: staff } = useQuery(
        ['loadStaff'],
        () => loadWorkers({ type: 'staff', status: 'active' }),
        { refetchOnWindowFocus: false }
    );

    const { mutate: sendLinkAppByEmail, isLoading: appLoading } = useMutation(sendLinkApp, {
        onSuccess: () => {
            AlertService.sendAlert([
                { text: `Mensagem enviada com sucesso.` }
            ]);

            formApp.setFieldValue('email', '');
            formApp.setFieldValue('message', '');
        }
    });

    const { data: company } = useQuery(
        ['getCompanyDetails'], () => getCompanyDetails(),
        { refetchOnWindowFocus: false }
    );

    const onSubmit = () => {
        formApp
            .validateFields()
            .then(values => sendLinkAppByEmail({ ...values }))
            .catch(() => AlertService.sendAlert([
                { text: 'Campo(s) com preenchimento inválido', type: 'error' }
            ]));
    }

    useEffect(() => {
        if (company) {
            formApp.setFieldValue('app_link', company?.data?.app_link);
        }
    }, [company]);

    return (
        <div className='content_page'>
            <DisplayAlert />
            <Card
                color='primary'
                headStyle={{
                    color: '#107B99',
                    fontWeight: 600
                }}
                title="Partilhar App">
                <Form
                    form={formApp}
                    name="form_table"
                    layout="vertical"
                    className="form_table"
                    autoComplete="off">

                    <Row gutter={20}>
                        <Col span={8}>
                            <Form.Item label="Staff" name="email">
                                <Select placeholder="Todos" size="large" allowClear>
                                    {staff?.data?.map((item: any) => (
                                        <Select.Option key={item.data.id} value={item.data.email}>{item.data.name}</Select.Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>

                        <Col span={16}>
                            <Form.Item label="App link" name="app_link">
                                <Input size="large" maxLength={255} placeholder="applink/app.pt" readOnly />
                            </Form.Item>
                        </Col>

                        <Col span={24}>
                            <Form.Item label="Mensagem" name="message">
                                <TextArea size="large" showCount maxLength={255} placeholder="Partilha uma mensagem com o motorista que vai aceder a app." />
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>

                <Row style={{ marginTop: 20, display: 'flex', justifyContent: 'space-between' }}>
                    <Col></Col>
                    <Col>
                        <Space>
                            <Button type="primary" loading={appLoading} size="large"
                                onClick={onSubmit}>Enviar</Button>
                        </Space>
                    </Col>
                </Row>
            </Card>
        </div>
    );
}